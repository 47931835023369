import * as React from "react"
import Seo from "../components/SEO"
import Container from "../components/Container"
import Hero from "../components/Hero/hero"
import Statistics from "../components/Stats/stats"
import Partners from "../components/Partners/partners"
import ImageBlock from "../components/ImageBlock/imageBlock"
import Testemonials from "../components/Testemonials/testemonials"
import Case from "../components/Case/case"
import { useSettings } from "../hooks/useSettings"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Footer from "../components/Footer/footer"
import { useRef } from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope, faPhoneAlt } from "@fortawesome/free-solid-svg-icons"

const IndexPage = () => {

  const data = useSettings()  
  const logo = getImage(data.wp.customSiteSettings.settings.siteLogo.localFile.childImageSharp)

  const testRef = useRef(null) 

  const scrollHandler = (a) =>{
    if(a === 'test' ){
      const section = document.querySelector( '#test' );
      section.scrollIntoView( { behavior: 'smooth', block: 'start' } );

    }
  }
  const ctaHandler = () =>{
      const section = document.querySelector( '#footer' );
      section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
  }


  
  return(
  <>
    <Container>
      <Seo title="Home" />
      <div className="flex flex-col justify-center lg:pt-12 lg:pb-24 pt-8 pb-8">
        <GatsbyImage image={logo} objectFit={'contain'}></GatsbyImage>
        <span className="uppercase font-bold cursor-pointer hover:underline text-dark contact__link mt-5"><FontAwesomeIcon icon={faEnvelope} className="mr-1" /> <a href="mailto:contact@spux.nl">contact@spux.nl</a></span>
        <span className="uppercase font-bold cursor-pointer hover:underline text-dark contact__link "><FontAwesomeIcon icon={faPhoneAlt} className="mr-1" /> <a href="tel:+31613357226">+31 (0)6 133 57 226</a></span>
      </div>
      {/* <Hero handler={scrollHandler} cta={ctaHandler}></Hero>
      <Statistics></Statistics>
      <Partners></Partners> */}
    </Container>
      {/* <ImageBlock></ImageBlock>
    <Container>
      <Testemonials refProp={testRef}></Testemonials>
      <Case cta={ctaHandler}></Case>
    </Container>
    <Footer></Footer> */}
  </>
)}

export default IndexPage
