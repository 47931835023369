import React from "react";

const Container = ({children, styles}) => {

    return(
        <div className={`container mx-auto 2xl:max-w-screen-xl ${styles} lg:px-0 px-8 h-screen flex flex-column justify-center`}>
            {children}
        </div>
    )
}

export default Container;