import { useStaticQuery, graphql } from "gatsby"
export const useSettings = () => {

  const data = useStaticQuery(
  graphql`
  query SettingsQuery {
    wp {
        customSiteSettings {
          settings {
            titel
            subtitel
            linkTekst
            ctaTekst
            headerAfbeelding {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            socialMedia {
              socialIcon {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              link
            }
            adres {
              straat
              postcode
            }
            email
            telefoonnummer
            statistieken {
                statistiek
                statistiekIcon {
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                statistiekGetal
                statistiekUnit
              }
              partners {
                partnerImage {
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            siteLogo {
              mediaItemUrl
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            siteLogoWit {
              mediaItemUrl
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            afbeelding1 {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            afbeelding2 {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            afbeelding3 {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            quote1
            quote2
            caseSellingPoints {
              sellingPoint
            }
            caseTitel
            caseCtaTekst
            caseAfbeelding {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            testemonials {
              testemonialTekst
              testemonialNaam
              testemonialBedrijf
              testemonialAfbeelding {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
  }
  `
)

  return data;
}

